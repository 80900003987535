<template>
  <div class="max-w-xs mx-auto">
    <div class="bg-white shadow-md border border-gray-400 rounded-lg max-w-sm mb-3">
      <div class="p-5">
        <h5 class="text-gray-900 font-bold text-3xl text-center tracking-tight mb-4">
          Iniciar sesión
        </h5>

        <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
          <a-form-item name="usuario">
            <a-input
              type="text"
              v-model:value="formState.usuario"
              @change="onChangeUsuario"
              placeholder="Usuario"
            >
              <template #prefix><ContactsOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
            </a-input>
          </a-form-item>
          <a-form-item name="constrasenia" class="mt-1">
            <a-input
              v-model:value="formState.constrasenia"
              type="password"
              placeholder="Contraseña"
            >
              <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
            </a-input>
          </a-form-item>

          <a-button
            type="primary"
            class="font-bold text-xs"
            htmlType="submit"
            :loading="isLoading"
            block
            @click="onSubmit"
            >INICIAR SESIÓN</a-button
          >

          <a-alert v-if="hasError" class="mt-4" :description="hasError" type="warning" closable />

          <a-button type="link" block class="mt-2 font-semibold text-xs"
            ><router-link to="forgot-password">¿HAS OLVIDADO TU CONTRASEÑA?</router-link></a-button
          >
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { ContactsOutlined, LockOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
// import publicIp from "public-ip";

export default {
  components: {
    ContactsOutlined,
    LockOutlined
  },
  setup() {
    const formRef = ref();
    const isLoading = ref(false);
    const store = useStore();
    const hasError = ref("");

    const formState = reactive({
      usuario: "",
      constrasenia: "",
      ip: ""
    });

    const onSubmit = async () => {
      try {
        hasError.value = "";
        isLoading.value = true;

        // await formRef.value.validate();
        // formState.ip = await publicIp.v4().catch(async (err) => {
        //   console.log(err);
        //   if (err.message.includes("Couldn't find your IP")) {
        //     formState.ip = "ip not found";
        //     await store.dispatch("user/login", formState);
        //   }
        // });

        formState.ip = "ip not found";
        await store.dispatch("user/login", formState);
      } catch (error) {
        console.log("error", error);

        if (!error.message.includes("Cannot read properties")) {
          hasError.value = error.message;
        } else {
          hasError.value =
            "No podemos conectarnos con el servidor, inténtelo de nuevo en unos minutos.";
        }
      } finally {
        isLoading.value = false;
      }
    };

    // rules
    const rules = {
      usuario: [
        { required: true, message: "El usuario es requerido", trigger: "blur" },
        { min: 8, message: "La cantidad mínima de caracteres es 8", trigger: "blur" },
        { max: 20, message: "La contraseña debe ser a lo mucho de 20 caracteres", trigger: "blur" }
      ],
      constrasenia: [
        {
          required: true,
          message: "La contraseña es requerida",
          trigger: "blur"
        },
        {
          min: 6,
          message: "La cantidad mínima de caracteres es 6",
          trigger: "blur"
        },
        {
          max: 20,
          message: "La contraseña debe a lo mucho de 20 caracteres",
          trigger: "blur"
        }
      ]
    };
    // end rules

    // validación de cantidad de caracteres
    const maxLength = 8;
    const regex = "^[0-9]+$";

    const onChangeUsuario = (e) => {
      formState.usuario = e.target.value.slice(0, maxLength);
      if (!new RegExp(regex, "g").test(e.target.value)) {
        formState.usuario = e.target.value.slice(0, -1);
      }
    };

    return {
      formRef,
      formState,
      onSubmit,
      rules,
      isLoading,
      hasError,
      onChangeUsuario
    };
  }
};
</script>
<style scoped></style>
